<script setup lang="ts">
  import { useMenuStore } from '@/stores/menu'
  import { useRouter } from 'vue-router'

  const menu = useMenuStore()
  const router = useRouter()

  router.afterEach(() => (menu.isVisibleOnMobile = false))
</script>

<template>
  <aside
    class="fixed bottom-0 top-16 left-0 bg-white border-r border-white-gray z-10"
    :class="{
      'w-full block': menu.isVisibleOnMobile,
      'hidden md:block min-w-40 md:w-navigation': !menu.isVisibleOnMobile && menu.isOpen,
      'hidden md:block w-16': !menu.isVisibleOnMobile && !menu.isOpen,
    }"
  >
    <div class="absolute inset-0 flex flex-col overflow-y-scroll overflow-x-visible">
      <slot></slot>
    </div>
    <div
      class="absolute cursor-pointer top-1/2 transform -translate-y-1/2 right-0 translate-x-full w-4 h-16 bg-white hover:bg-white-gray rounded-tr-xl rounded-br-xl border border-white-gray justify-center items-center hidden md:inline-flex snap-mandatory"
      @click="menu.toggleOpen"
    >
      <des-icon v-if="menu.isOpen" class="w-4 h-3" icon="fa-light fa-chevron-left" />
      <des-icon v-else class="w-4 h-3" icon="fa-light fa-chevron-right" />
    </div>
  </aside>
</template>
