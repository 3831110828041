import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useMenuStore = defineStore('menu', () => {
  const isOpen = ref(true)
  const isVisibleOnMobile = ref(false)

  function toggleOpen() {
    isOpen.value = !isOpen.value
  }

  return { isOpen, isVisibleOnMobile, toggleOpen }
})
