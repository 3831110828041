<script setup lang="ts">
  import { useMediaQuery } from '@vueuse/core'
  import { onMounted, ref, watch } from 'vue'

  const props = defineProps<{
    open?: boolean
    bottom?: boolean
    right?: boolean
    left?: boolean
    scroll?: boolean
  }>()

  const element = ref<HTMLElement | null>(null)
  const flip = ref<boolean>(false)
  const mobileLeft = ref(0)
  const isMobile = useMediaQuery('(max-width: 640px)')

  watch(
    () => props.open,
    () => {
      if (!element.value?.parentElement) return

      const rect = element.value.parentElement.getBoundingClientRect()
      flip.value = !isMobile.value && rect.top > 250 && rect.bottom + 250 > window.innerHeight
    },
  )

  onMounted(() => setLeft())

  function setLeft() {
    if (element.value?.parentElement) {
      mobileLeft.value = -element.value.parentElement.getBoundingClientRect().left
    }
  }
</script>

<template>
  <Transition
    v-on:after-enter="setLeft"
    enter-from-class="scale-y-0 pointer-events-none"
    leave-to-class="scale-y-0 pointer-events-none"
  >
    <div
      v-show="open"
      ref="element"
      class="absolute flex flex-col bg-white min-w-fit w-40 border border-white-gray p-2 gap-2 z-50 shadow-lg transition-transform rounded-lg max-md:!top-full max-md:!min-w-0 max-md:!mx-0"
      :class="{
        'top-0 right-full mx-3': !bottom && !right && !flip,
        'top-full right-0 my-1': bottom && !right && !flip,
        'top-full left-0 my-1': bottom && right && !flip,
        'top-0 left-full mx-3': !bottom && right && !flip,
        'bottom-0 right-full mx-3': !bottom && !right && flip,
        'bottom-full right-0 my-1': bottom && !right && flip,
        'bottom-full left-0 my-1': bottom && right && flip,
        'bottom-0 left-full mx-3': !bottom && right && flip,
        'origin-bottom': flip,
        'origin-top': !flip,
        'overflow-y-scroll max-h-72': scroll,
      }"
      :style="isMobile ? { left: `calc(${mobileLeft}px + 1.5rem)`, width: `calc(100vw - 3rem) !important` } : {}"
    >
      <slot></slot>
    </div>
  </Transition>
</template>
