<script setup lang="ts">
  import { computed } from 'vue'
  import { useMenuStore } from '@/stores/menu'
  import { useRouter } from 'vue-router'

  const menu = useMenuStore()
  const router = useRouter()
  const props = defineProps<{
    highlighted?: boolean
    to?: string
    href?: string
    click?: () => void
    icon?: string
    name: string
    postfix?: string
  }>()

  const onClick = function () {
    if (props.to) {
      router.push(props.to)
    } else if (props.href) {
      location.href = props.href
    } else if (props.click) {
      props.click()
    }
  }

  const showLabels = computed(() => menu.isOpen || menu.isVisibleOnMobile)
</script>

<template>
  <a
    @click="onClick()"
    class="py-2 cursor-pointer leading-4 rounded-lg justify-start items-center text-sm gap-2 inline-flex hover:bg-white-gray select-none"
    :key="menu.isOpen.toString()"
    :class="{ 'text-blue-60': highlighted, 'px-3': showLabels, 'justify-center': !showLabels }"
    v-des-tooltip:right="showLabels ? undefined : name"
  >
    <des-icon v-if="icon" :icon="icon" class="h-icon w-icon" />
    <span class="text-ellipsis overflow-hidden" v-if="showLabels">
      {{ name }}
      <span class="text-black-40 text-xs">{{ postfix }}</span>
    </span>
  </a>
</template>
