<script setup lang="ts">
  import { useUserStore } from '@/stores/user'
  import { onMounted } from 'vue'
  import Screencastomatic from '@/helpers/screencastomatic'

  const user = useUserStore()

  onMounted(() => Screencastomatic.setupScreencastomaticScript(user.user))
</script>

<template>
  <div class="absolute bottom-2 ml-5 hidden md:block">
    <div id="screencastomatic-target" class="absolute opacity-0 w-0 h-0"></div>
  </div>
</template>
