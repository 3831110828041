<script setup lang="ts">
  import { onMounted } from 'vue'

  declare const Canny: Function

  onMounted(() => {
    // @ts-ignore
    // prettier-ignore
    !function(w,d,i,s){function l(){if(!d.getElementById(i)){let f=d.getElementsByTagName(s)[0],e=d.createElement(s);e.type="text/javascript",e.async=!0,e.src="https://canny.io/sdk.js",f.parentNode.insertBefore(e,f)}}if("function"!=typeof w.Canny){var c=function(){c.q.push(arguments)};c.q=[],w.Canny=c,"complete"===d.readyState?l():w.attachEvent?w.attachEvent("onload",l):w.addEventListener("load",l,!1)}}(window,document,"canny-jssdk","script"); // eslint-disable-line
    Canny('initChangelog', {
      appID: '5b0f2b723a3efa64491db9d2',
      position: 'bottom',
      align: 'right',
      theme: 'light',
    })
  })
</script>

<template>
  <div
    v-des-track="'canny-bar-clicked'"
    data-canny-changelog
    class="relative rounded hover:bg-white-gray cursor-pointer transition-colors hidden md:inline-flex"
  >
    <des-icon icon="fa-light fa-bell" class="w-6 h-6 p-2"></des-icon>
  </div>
</template>

<style>
  :root .Canny_Badge {
    top: theme('spacing.2');
    left: theme('spacing.6');
    background-color: theme('colors.red.50');
    width: theme('spacing.2');
    height: theme('spacing.2');
    padding: 0;
  }
</style>
