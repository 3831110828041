import { ref } from 'vue'
import { http } from '@/helpers/http'
import { defineStore } from 'pinia'
import { useStorage } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useModalStore } from './modal'
import { useUserStore } from './user'
import type { TLandingPage, TLandingPageMeta } from '@/types/landing-pages'
import type { TProject } from '@/types/projects'

export const useLandingPagesStore = defineStore('landing-pages', () => {
  const modals = useModalStore()
  const user = useUserStore()
  const router = useRouter()
  const store = useStorage<TLandingPageMeta | null>('landingPageTempData', {} as TLandingPageMeta)
  const landingPages = ref<TLandingPage[]>([])
  const projectId = ref<number | null>(null)
  const draftsIdsWithoutThumbnails = ref<number[]>([])
  const generateRunning = ref<boolean>(false)
  const generateTimeout = ref<NodeJS.Timeout | null>(null)
  const languages = [
    { name: 'English', label: '🇬🇧 English', code: 'en' },
    { name: 'Dutch', label: '🇳🇱 Dutch', code: 'nl' },
    { name: 'French', label: '🇫🇷 French', code: 'fr' },
    { name: 'German', label: '🇩🇪 German', code: 'de' },
    { name: 'Hungarian', label: '🇭🇺 Hungarian', code: 'hu' },
    { name: 'Italian', label: '🇮🇹 Italian', code: 'it' },
    { name: 'Polish', label: '🇵🇱 Polish', code: 'pl' },
    { name: 'Portuguese', label: '🇵🇹 Portuguese', code: 'pt' },
    { name: 'Romanian', label: '🇷🇴 Romanian', code: 'ro' },
    { name: 'Spanish', label: '🇪🇸 Spanish', code: 'es' },
  ]

  async function fetch() {
    const res = await http.get<{
      results: TLandingPage[]
    }>(`/landing-pages`)

    landingPages.value = res?.results ?? []

    draftsIdsWithoutThumbnails.value = landingPages.value.filter((item) => !item.thumbnail).map((item) => item.id)

    if (draftsIdsWithoutThumbnails.value.length > 0) {
      generateRunning.value = true
      checkThumbnail()
    }
  }

  async function checkThumbnail() {
    const id = draftsIdsWithoutThumbnails.value.length > 0 ? draftsIdsWithoutThumbnails.value[0] : null
    if (!id) {
      generateRunning.value = false
      return
    }

    draftsIdsWithoutThumbnails.value.shift()

    if (generateRunning.value) {
      await generateThumbnail(id)
      generateTimeout.value = setTimeout(() => {
        checkThumbnail()
      }, 1000)
    } else {
      generateRunning.value = false
    }
  }

  async function generateThumbnail(id: number) {
    const result = await http.get<{ thumbnail: string }>(`/landing-pages/generate_thumbnail/${id}`)
    if (result) {
      const landingPage = landingPages.value.find((page) => page.id === id)
      if (landingPage) {
        landingPage.thumbnail = result.thumbnail
      }
    }
  }

  async function getProjectMeta(id: number) {
    const res = await http.get<TLandingPageMeta>(`/landing-pages/project/${id}`)

    return res ?? null
  }

  async function rename(id: number, title: string): Promise<boolean> {
    try {
      const res = await http.post<{ result: boolean }>(`/landing-pages/rename`, { id, title })
      return res.result || false
    } catch {
      return false
    }
  }

  async function remove(id: number): Promise<boolean> {
    try {
      const res = await http.delete<{ result: boolean }>(`/landing-pages/${id}`)
      res.result && fetch()
      return res.result || false
    } catch {
      return false
    }
  }

  async function removeMany(ids: number[]): Promise<boolean> {
    try {
      const res = await http.post<{ result: boolean }>(`/landing-pages/delete-many`, { ids })
      res.result && fetch()
      return res.result || false
    } catch {
      return false
    }
  }

  async function create(project: TProject): Promise<boolean> {
    try {
      const res = await http.get<{ result: boolean; summary?: string }>(`/projects/${project.id}/summary`)
      if (!res.result) return false

      const lang = await http.get<{ result: boolean; detected?: string }>(`/projects/${project.id}/language`)
      const language = languages.find((item) => item.code === lang.detected) ?? languages[0]

      const meta = await modals.open<TLandingPageMeta | undefined>(
        () => import('@/components/modal/DesModalLandingPageMeta.vue'),
        {
          id: project.id,
          author: `${user.user.first_name ?? ''} ${user.user.last_name ?? ''}`.trim(),
          title: project.name,
          subtitle: '',
          summary: res.summary,
          language: { name: language.name, code: language.code },
        },
      )

      store.value = meta
      if (!meta) return false

      router.push('/landing-pages/create')
      return true
    } catch {
      return false
    }
  }

  return {
    landingPages,
    projectId,
    languages,
    fetch,
    getProjectMeta,
    rename,
    remove,
    removeMany,
    create,
  }
})
