import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faAlignJustify,
  faAlignLeft,
  faAlignSlash,
  faArrowDown,
  faArrowDownToBracket,
  faArrowDownToSquare,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightLong,
  faArrowsMaximize,
  faArrowsUpDownLeftRight,
  faArrowUp,
  faArrowUpFromBracket,
  faArrowUpRight,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faB,
  faBars,
  faBell,
  faBookOpen,
  faBriefcase,
  faBullhorn,
  faCameraViewfinder,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleNotch,
  faCirclePause,
  faCirclePlay,
  faCircleQuestion,
  faCity,
  faClock,
  faClockRotateLeft,
  faCloud,
  faCloudArrowUp,
  faCloudCheck,
  faCode,
  faCog,
  faCoins,
  faCommand,
  faCommentLines,
  faCopy,
  faCreditCard,
  faCube,
  faEllipsis,
  faEllipsisVertical,
  faEnvelope,
  faExclamation,
  faExclamationCircle,
  faEye,
  faFile,
  faFileCode,
  faFileImage,
  faFileInvoice,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFloppyDisk,
  faFolder,
  faFolderPlus,
  faGear,
  faGlobe,
  faGlobePointer,
  faGraduationCap,
  faGrid2,
  faGripLines,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faH6,
  faHeadphones,
  faHeart,
  faHighlighter,
  faImage,
  faImages,
  faInfo,
  faInfoCircle,
  faItalic,
  faKeyboard,
  faLaptopMobile,
  faLink,
  faListCheck,
  faListOl,
  faListUl,
  faLock,
  faMagnifyingGlass,
  faMerge,
  faNote,
  faObjectGroup,
  faPaintbrushPencil,
  faPalette,
  faParagraphRtl,
  faPause,
  faPen,
  faPenField,
  faPeopleGroup,
  faPlay,
  faPlus,
  faQrcode,
  faQuoteRight,
  faRectangle,
  faRectanglesMixed,
  faRectangleVertical,
  faRefresh,
  faRemoveFormat,
  faRepeat,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShare,
  faShieldCheck,
  faShoppingBasket,
  faSignPost,
  faSparkles,
  faSpellCheck,
  faSquare,
  faStar,
  faStrikethrough,
  faSubtitles,
  faSync,
  faTags,
  faText,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faUnderline,
  faUndo,
  faUser,
  faUserClock,
  faUserGroup,
  faUserGroupSimple,
  faUserHair,
  faUserHairLong,
  faUserTie,
  faVideo,
  faVolume,
  faVolumeHigh,
  faVolumeLow,
  faVolumeOff,
  faWaveSine,
} from '@fortawesome/pro-light-svg-icons'

import {
  faBolt as fsBolt,
  faBriefcase as fsBriefcase,
  faCirclePlay as fsCirclePlay,
  faCrown as fsCrown,
  faHeadphones as fsHeadphones,
  faHeart as fsHeart,
  faLock as fsLock,
  faPause as fsPause,
  faPlay as fsPlay,
  faStar as fsStar,
} from '@fortawesome/pro-solid-svg-icons'

import {
  faYoutube as fbYoutube,
  faSquareFacebook as fbSquareFacebook,
  faXTwitter as fbXTwitter,
  faLinkedin as fbLinkedin,
} from '@fortawesome/free-brands-svg-icons'

library.add(
  // @ts-ignore
  faAlignJustify,
  faAlignLeft,
  faAlignSlash,
  faArrowDown,
  faArrowDownToBracket,
  faArrowDownToSquare,
  faArrowLeft,
  faArrowLeftLong,
  faArrowRight,
  faArrowRightFromBracket,
  faArrowRightLong,
  faArrowsMaximize,
  faArrowsUpDownLeftRight,
  faArrowUp,
  faArrowUpFromBracket,
  faArrowUpRight,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faB,
  faBars,
  faBell,
  faBookOpen,
  faBriefcase,
  faBullhorn,
  faCameraViewfinder,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleInfo,
  faCircleNotch,
  faCirclePause,
  faCirclePlay,
  faCircleQuestion,
  faCity,
  faClock,
  faClockRotateLeft,
  faCloud,
  faCloudArrowUp,
  faCloudCheck,
  faCode,
  faCog,
  faCoins,
  faCommand,
  faCommentLines,
  faCopy,
  faCreditCard,
  faCube,
  faEllipsis,
  faEllipsisVertical,
  faExclamation,
  faExclamationCircle,
  faEye,
  faEnvelope,
  faFile,
  faFileCode,
  faFileImage,
  faFileInvoice,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFloppyDisk,
  faFolder,
  faFolderPlus,
  faGear,
  faGlobe,
  faGlobePointer,
  faGraduationCap,
  faGrid2,
  faGripLines,
  faH1,
  faH2,
  faH3,
  faH4,
  faH5,
  faH6,
  faHeadphones,
  faHeart,
  faHighlighter,
  faImage,
  faImages,
  faInfo,
  faInfoCircle,
  faItalic,
  faKeyboard,
  faLaptopMobile,
  faLink,
  faListCheck,
  faListOl,
  faListUl,
  faLock,
  faMagnifyingGlass,
  faMerge,
  faNote,
  faObjectGroup,
  faPaintbrushPencil,
  faPalette,
  faParagraphRtl,
  faPause,
  faPen,
  faPenField,
  faPeopleGroup,
  faPlay,
  faPlus,
  faQrcode,
  faQuoteRight,
  faRectangle,
  faRectanglesMixed,
  faRectangleVertical,
  faRefresh,
  faRemoveFormat,
  faRepeat,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShare,
  faShieldCheck,
  faShoppingBasket,
  faSignPost,
  faSparkles,
  faSpellCheck,
  faSquare,
  faStar,
  faStrikethrough,
  faSubtitles,
  faSync,
  faTags,
  faText,
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashCan,
  faUnderline,
  faUndo,
  faUser,
  faUserClock,
  faUserGroup,
  faUserGroupSimple,
  faUserHair,
  faUserHairLong,
  faUserTie,
  faVideo,
  faVolume,
  faVolumeHigh,
  faVolumeOff,
  faVolumeLow,
  faWaveSine,
  fbSquareFacebook,
  fbLinkedin,
  fbXTwitter,
  fbYoutube,
  fsBolt,
  fsBriefcase,
  fsCirclePlay,
  fsCrown,
  fsHeadphones,
  fsHeart,
  fsLock,
  fsPause,
  fsPlay,
  fsStar,
)

export { FontAwesomeIcon, FontAwesomeLayers }
